// -- main variables
$main_font_family: 'Montserrat', sans-serif;
$main_font_size: 16px;
$main_font_weight: 400;
$main_line_height: 1.6;

$colors: (
        "white": #ffffff,
        "red": #D7272F,
        "blue": #34C4E3,
        "dark-blue": #005688,
        "yellow": #DDDA58,
        "grey": #888888,
        "light-grey": #E5E2DE,
        "dark-grey": #231f20,
        "black": #000000,
);


$main_text_color: map-get($colors,"black");
$main_bg: map-get($colors,"white");
$main_color: map-get($colors,"dark-blue");
$main_border_color: map-get($colors,"light-grey");
$main_border: 1px solid $main_border_color;
$main_border_radius: 0px;

$second_font_family: $main_font_family;
$headline_bg: map-get($colors,"black");

$link_color: $main_color;
$link_text_decoration: none;
$link_hover_color: darken($main_color, 20%);
$link_hover_text_decoration: none;
$error_color: map-get($colors,"red");

$main_font_size_map: (
  min: 14px,
  max: 16px
);